/* Fonts start */

/* poppins-100 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/poppins-v21-latin-100.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-200 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/poppins-v21-latin-200.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/poppins-v21-latin-300.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/poppins-v21-latin-regular.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/poppins-v21-latin-500.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/poppins-v21-latin-600.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/poppins-v21-latin-700.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/poppins-v21-latin-800.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-900 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/poppins-v21-latin-900.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* Fonts end */

body {
  @apply bg-primary;
  font-family: "Poppins";
}
.slick-dots {
  display: flex !important;
  bottom: -70px !important;
  width: 94% !important;
}
.slider-1 .slick-dots li {
  width: 100% !important;
  margin: 0 !important;
  height: auto !important;
}
.slider-1 .slick-dots li button {
  width: 100% !important;
  height: auto !important;
}
.slider-1 .slick-dots li button:before {
  width: 100% !important;
  height: 2px !important;
  content: "" !important;
  background-color: #0f0f0f40;
}
.slider-1 .slick-dots li.slick-active button:before {
  background-color: #0f0f0f !important;
  height: 6px !important;
  top: -2px;
}
.slider-1 .slick-prev,
.slick-next {
  bottom: -70px !important;
  top: inherit !important;
  transform: translate(0, 0) !important;
}
.slider-1 .slick-prev,
.slider-1 .slick-prev:hover,
.slider-1 .slick-prev:focus {
  right: 20px !important;
  left: inherit;
  height: 36px !important;
  width: 36px !important;
  border-radius: 50%;
  border: 1px solid #000;
  background: url(./assets/images/slider_arrow.svg);
  color: #000 !important;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.slick-prev:before {
  content: "" !important;
}
.slider-1 .slick-next,
.slider-1 .slick-next:hover,
.slider-1 .slick-next:focus {
  left: inherit;
  height: 36px !important;
  width: 36px !important;
  border-radius: 50%;
  border: 1px solid #000;
  background: url(./assets/images/slider_arrow.svg);
  color: #ffff;
  rotate: 180deg;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.slick-next:before {
  content: "" !important;
}

.Titile {
  background: linear-gradient(
    90deg,
    rgba(113, 113, 113, 0.13) 0%,
    rgba(113, 113, 113, 0.15) 44%,
    rgba(113, 113, 113, 0.08) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Poppins;
  font-size: 64px;
  font-weight: 800;
  line-height: 76.8px;
  letter-spacing: -0.04em;
  text-align: center;
}
.Main-circle {
  width: 420px;
  height: 420px;
  background: #71717124;
}
.slider-2 .slider-2 .slick-prev,
.slick-next {
  bottom: -70px !important;
  top: inherit !important;
  transform: translate(0, 0) !important;
}
.slider-2 .slick-prev,
.slider-2 .slick-prev:hover,
.slider-2 .slick-prev:focus {
  right: 100px !important;
  top: -40px;
  left: inherit;
  height: 36px !important;
  width: 36px !important;
  border-radius: 50%;
  border: 1px solid #000;
  background: url(./assets/images/slider_arrow.svg);
  color: #000 !important;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.slick-prev:before {
  content: "" !important;
}
.slider-2 .slick-next,
.slider-2 .slick-next:hover,
.slider-2 .slick-next:focus {
  right: 20px !important;
  top: -58px !important;
  left: inherit;
  height: 36px !important;
  width: 36px !important;
  border-radius: 50%;
  border: 1px solid #000;
  background: url(./assets/images/slider_arrow.svg);
  color: #ffff;
  rotate: 180deg;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.dotes-border::after {
  content: "";
  height: 2px;
  width: 90%;
  border: 1px dashed #00000066;
  position: absolute;
  top: 32%;
  transform: translateY(-50%);
  left: 0;
  z-index: -1;
}

.login-shape {
  width: 260px;
  height: 45px;
  background-color: #717171;
  clip-path: polygon(0% 0%, 75% 0%, 80% 50%, 75% 100%, 0% 100%);
  position: absolute;
  top: 28px;
  display: flex;
  align-items: center;
  left: 0;
}
.instructor_profile_shape {
  position: absolute;
  top: 22px;
  left: 0;
  height: 30px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.TimeSlot .css-b62m3t-container:nth-child(1) {
  min-height: 80px !important;
}
.TimeSlot .css-b62m3t-container .css-13cymwt-control {
  height: 100%;
  background-color: #dad8d0 !important;
  border-radius: 16px;
  border: none;
}
.css-t3ipsp-control {
  min-height: 80px !important;
  background-color: #dad8d0;
  border-radius: 16px;
  border: none;
}
.css-t3ipsp-control:hover {
  border: none !important;
  box-shadow: none !important;
  background-color: #dad8d0 !important;
}

.css-13cymwt-control {
  min-height: 80px !important;
  background-color: #dad8d0;
  border-radius: 16px;
  border: none;
}
.css-13cymwt-control:hover {
  border: none !important;
  box-shadow: none !important;
  background-color: #dad8d0 !important;
}
.css-1p3m7a8-multiValue {
  font-size: 20px;
  @apply bg-primary !important;
}
.css-hlgwow {
  padding-left: 24px !important;
}
.dispute_shape {
  clip-path: polygon(95% 0%, 100% 56%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
}
.Toastify {
  z-index: 999999 !important;
  position: relative;
}

/* spnner css start */
.spnner {
  height: 100vh;
  overflow: hidden;
  width: 100%;
  background-color: #0000009f;
  backdrop-filter: blur(4px);
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  margin: 9px;
  border: 9px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* spnner css end */
.customradius {
  border-radius: 15px !important;
}
/* .slick-slide {
  padding-right:24px;
} */
.customradiusBlack {
  color: #000 !important;
}
.react-calendar {
  width: 100% !important;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #25c277;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  margin-top: 4px;
}
.DonutChart .apexcharts-canvas {
  direction: rtl !important;
  margin-top: 30px !important;
}
.FinanceDashboardChart .apexcharts-legend {
  flex-direction: row-reverse;
  overflow: hidden;
}

.FinanceDashboardChart foreignObject,
.FinanceDashboardChart svg {
  height: 430px !important;
}
.eye_bg {
  backdrop-filter: blur(6.400000095367432px);
}
.PhoneInputInput {
  height: 70px !important;
  background-color: #dad8d0 !important;
  padding: 0 24px !important;
}
.Profile,
.Profile .css-b62m3t-container {
  height: 70px !important;
}
.Profile .css-13cymwt-control {
  height: 70px !important;
  min-height: 70px !important;
  background-color: #dad8d0 !important;
  border: none !important;
  border-radius: 12px !important;
}

@media (max-width: 879px) {
  .dotes-border::after {
    display: none;
  }
}
@media (max-width: 768px) {
  .slick-dots {
    bottom: -20px !important;
  }
}
@media (max-width: 568px) {
  .Titile {
    font-size: 53px;
    line-height: 55px;
  }
}
*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
*::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #aeaeae;
}

*::-webkit-scrollbar-track:hover {
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:active {
  background-color: #fefeff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #6e6e6e;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #6e6e6e;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #6e6e6e;
}
.remove-icon{
  -webkit-appearance: none;
  -moz-appearance: none;
}
/* .iconafter::after
{
  content: "";
  height: 20px;
  width: 20px;
  background-color: #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2%;
  border-radius: 50%;
  z-index: 99;
} */


.input-error {
  color:#CB3530;
}

#exprince::first-letter{
  text-transform: capitalize;
}